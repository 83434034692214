const URL = "/backend/api/v2/"


type DefaultOptionsProps = {
    headers?: HeadersInit;
    options?: RequestInit;
  };
  
  export const defaultOptions = ({ headers, options }: DefaultOptionsProps = { headers: {}, options: {} }) => ({
    headers: {
      "Content-type": "application/json",
      ...headers,
    },
    credentials: "include" as RequestCredentials,
    cache: "no-store" as RequestCache,
    ...options,
});
  

export const verify_email = (verification_token: string) => {
    const raw = JSON.stringify({verification_token});
    const requestOptions = defaultOptions({
        options: {
          method: "POST",
          body: raw,
        }
    });

    return fetch(`${URL}user/verify_email`, requestOptions).then((res) => res.json());
}