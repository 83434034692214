import BlueBg from "../../components/BlueBg";
import EmailVerificationModal from "../../components/Modals/EmailVerificationModal";

const VerifyEmail = () => {

    return (
        <>
            <BlueBg>
                <EmailVerificationModal />
            </BlueBg>
        </>
    )
}

export default VerifyEmail;

