import classes from "./BlueBg.module.scss"
import React, { FC } from "react";
import { useEffect, useState } from 'react';

interface Props {

}

const BlueBg: FC<Props> = ({children}) => {
    const [sw, setSW] = useState(0);

    useEffect(() => {
        resizeListener();
        window.addEventListener("resize", resizeListener);
        return () => {
          // remove resize listener
          window.removeEventListener("resize", resizeListener);
        };
    }, [])


    const resizeListener = () => {
        setSW(window.innerWidth);
    };

    if (sw <= 500) {
        return (
            <>
                <div className={classes.BackgroundMobile}>
                    {children}
                </div>
            </>
        );
    } else {
        return (
            <div className={classes.BackgroundDesktop}>
                {children}
            </div>
        ); 
    }
}

export default BlueBg;