import { useEffect } from "react";
import { useParams } from 'react-router';

const VerifyEmail = () => {
    const { code } = useParams();

    useEffect(() => {
        window.location.replace(`hyjynx://forgotPass?code=${code}`);
    },[])

    return (
        <>
        </>
    )
}

export default VerifyEmail;

