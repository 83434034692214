import classes from "./EmailVerificationModal.module.scss"
import { useState, useEffect } from 'react';
import { icons } from "../../../utils/asset";
import ActionButton from "../../Buttons/ActionButton";
import { verify_email } from "../../../api/User";
import { useParams } from 'react-router';

const EmailVerificationModal = () => {
    const { id } = useParams();

    const [loading, setLoading] = useState<boolean>(true);
    const [title, setTitle] = useState<string>("Checking Email");
    const [text, setText] = useState<string>("Please wait, this process should take only a few seconds.");
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        verifyEmail()
    }, [])

    useEffect(() => {
        setTitle("Email Verified")
        setText("You can now make deposits, purchases, withdrawals and receive prizes for free")
    }, [loading]);

    const verifyEmail = async () => {
        const code: string | undefined = id 
       
        if (code) {
            await verify_email(code).then(data => {
                if(data.status < 400) {
                    setSuccess(true)
                    setLoading(false)
                } else {
                    setSuccess(false)
                    setTitle("Verification Failed")
                    setText("Your email is not verified yet, please try generating another verification link from our app")
                }
            })
        }
    }

    const openApp = () => {
        window.location.replace("hyjynx://");
    }

    return (
        <>
            <div className={classes.ModalBody}>
                { loading ? (
                    <div className={classes.Loader}></div>
                ) : (
                    <img src={success ? icons.GREEN_TICK : icons.EMAIL_FAIL} className={classes.GreenTick} alt="green tick" />
                )}
                <div className={classes.TextBox}>
                    <p className={classes.Title}>{title}</p>
                    <p className={classes.Text}>{text}</p>
                </div>
                {!loading && (
                    <div onClick={() => { openApp() }}>
                        <ActionButton text="Back to HyJynx" />
                    </div>
                )}
            </div>
        </>
    )
}

export default EmailVerificationModal;
