import { Routes, Route } from 'react-router';
import VerifyEmail from './pages/VerifyEmail';
import ForgotPass from './pages/ForgotPass';
import Home from './pages/Home';

const RouterConfig = () => {

    return (
        <section className="page-content">
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/verify-email/:id" element={<VerifyEmail />} />
                <Route path="/forgot-pass/:code" element={<ForgotPass />} />
            </Routes>
        </section>
    )
}

export default RouterConfig;