import { useEffect } from "react";

const Home = () => {
    useEffect(() => {
        window.location.replace(`hyjynx://`);

        setTimeout(() => {
            window.location.replace(
              "https://apps.apple.com/gb/app/id1575892302"
            );
        }, 6000);
    },[])

    return (
        <>
        </>
    )
}

export default Home;