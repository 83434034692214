import classes from "./ActionButton.module.scss"
import { useState } from 'react';

interface Props {
    text: string;
}

const ActionButton = (props: Props) => {
    const [pressed, setPressed] = useState<boolean>(false);

    const { text } = props;
    
    const pressButton = () => {
        setPressed(true)
        setTimeout(() => {
            setPressed(false)
        }, 200)
    }

    return (
        <div className={pressed ? 
            `${classes.ButtonBody} ${classes.PressedColor}` : 
            classes.ButtonBody} 
            onClick={() => { pressButton()}}
        >
            <div className={`${classes.Detail} ${classes.DLeft}`}></div>
            <div className={`${classes.Detail} ${classes.DRight}`}></div>
            <p>{text.toUpperCase()}</p>
        </div>
    )
}

export default ActionButton;